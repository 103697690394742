import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {reset_password_confirm, clear_user_module, RESET_PASSWORD_CONFIRM} from '../../redux/users';
import {useParams, useHistory} from 'react-router-dom';
import InputPassword from '../../components/input_password';
import Button from '../../components/button';
import Alert from '../../components/alert';

const ResetPassword = () => {
    let history = useHistory();
    let dispatch = useDispatch();
    const _reset_password_confirm = useSelector((state) => state.users_module._reset_password_confirm);
    const {uid, token} = useParams();
    const [form, setForm] = useState({
        new_password1: '',
        new_password2: '',
        uid: uid,
        token: token,
    })
    const [msj, setMsj] = useState({
        type: '',
        text: '',
        show: false,
    });

    useEffect(() => {
        if (_reset_password_confirm.status === 200) {
            dispatch(clear_user_module(RESET_PASSWORD_CONFIRM));
            setMsj({
                type: 'bg-rgba-success',
                text: 'Se ha cambiado la contraseña correctamente',
                show: true,
            });
            //esperar 5 segundos
            setTimeout(() => {
                history.push('/');
            }, 5000);
        } else if (_reset_password_confirm.status !== 0) {
            setMsj({
                type: 'bg-rgba-danger',
                text: _reset_password_confirm.message,
                show: true,
            });
            dispatch(clear_user_module(RESET_PASSWORD_CONFIRM));

        }
    }, [_reset_password_confirm, dispatch, history]);


    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate data
        if (form.new_password1 !== form.new_password2) {
            setMsj({
                type: 'bg-rgba-danger',
                text: 'Las contraseñas no coinciden',
                show: true,
            });
            return;
        }

        dispatch(reset_password_confirm(form));
    }

    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            <div className="content-wrapper">
                <div className="content-header row">
                </div>
                <div className="content-body">
                    {/* reset password start */}
                    <section className="row flexbox-container">
                        <div className="col-xl-7 col-10">
                            <div className="card bg-authentication mb-0">
                                <div className="row m-0">
                                    {/* left section-login */}
                                    <div className="col-md-6 col-12 px-0">
                                        <div
                                            className="card disable-rounded-right d-flex justify-content-center mb-0 p-2 h-100">
                                            <div className="card-header pb-1">
                                                <div className="card-title">
                                                    <h4 className="text-center mb-2">Recuperar contraseña</h4>
                                                </div>
                                            </div>
                                            <div className="card-content">
                                                <div className="card-body">
                                                    <form className="mb-2" onSubmit={handleSubmit}>
                                                        <Alert
                                                            message={msj.text}
                                                            type={msj.type}
                                                            show={msj.show}
                                                        />
                                                        <InputPassword
                                                            type="password"
                                                            name="new_password1"
                                                            id="new_password1"
                                                            id_div1="show_hide_password"
                                                            id_div2="password"
                                                            label="Nueva contraseña"
                                                            placeholder="Nueva contraseña"
                                                            value={form.new_password1}
                                                            onChange={handleChange}
                                                        />
                                                        <InputPassword
                                                            type="password"
                                                            name="new_password2"
                                                            id="new_password2"
                                                            id_div1="show_hide_password1"
                                                            id_div2="password1"
                                                            label="Confirmar contraseña"
                                                            placeholder="Confirmar contraseña"
                                                            value={form.new_password2}
                                                            onChange={handleChange}
                                                        />

                                                        <Button
                                                            text="Cambiar contraseña"
                                                            type="submit"
                                                            classes="btn-primary  btn-block"
                                                        />
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* right section image */}
                                    <div className="col-md-6 d-md-block d-none text-center align-self-center p-3">
                                        <img className="img-fluid"
                                             src={window.location.origin + "/base/app-assets/images/pages/reset-password.png"}
                                             alt="branding logo"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* reset password ends */}
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;