import {
    URLAPI,
    EMPLOYEE_PATH,
    EMPLOYEE_CREATE_PATH,
    EMPLOYEE_UPDATE_PATH,
    EMPLOYEE_CREATE_LINK_PATH,
    EMPLOYEE_UPDATEPHOTO_PATH,
    EMPLOYEE_IMPORT_PATH,
    EMPLOYEE_VALIDATE_LINK_PATH,
    TASK_PATH,
    EMPLOYEE_REPORT_PATH,
    EMPLOYEE_REPORT_CHECK_PATH
} from "../config/index";
import axios from "axios";
import {LOGOUT} from './users'

const initialState = {
    _get_all_employees: {
        data: [],
        status: 0,
        message: {},
    },
    _create_employee: {
        data: {},
        status: 0,
        message: {},
    },
    _update_employee: {
        data: {},
        status: 0,
        message: {},
    },
    _create_link: {
        data: {},
        status: 0,
        message: {},
    },
    _update_photo: {
        data: {},
        status: 0,
        message: {},
    },
    _import_employee: {
        data: {},
        status: 0,
        message: {},
    },
    _capture_photo: {

        data: {},
        status: 0,
        message: {}
    },
    _get_report_employee: {
        data: {},
        status: 0,
        message: ""
    }
};

const GET_ALL_EMPLOYEES = "_get_all_employees";
export const CREATE_EMPLOYEE = "_create_employee";
export const UPDATE_EMPLOYEE = "_update_employee";
export const CREATE_LINK = "_create_link";
export const UPDATE_PHOTO = "_update_photo";
export const IMPORT_EMPLOYEE = "_import_employee";
export const CAPTURE_PHOTO = "_capture_photo";

export const GET_REPORT_EMPLOYEE = "GET_REPORT_EMPLOYEE"

export const employees_module = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_EMPLOYEES:
            return {
                ...state,
                _get_all_employees: action.payload,
            };
        case CREATE_EMPLOYEE:
            return {
                ...state,
                _create_employee: action.payload,
            };
        case UPDATE_EMPLOYEE:
            return {
                ...state,
                _update_employee: action.payload,
            };
        case CREATE_LINK:
            return {
                ...state,
                _create_link: action.payload,
            };
        case CAPTURE_PHOTO:
            return {
                ...state,
                _capture_photo: action.payload,
            };

        case UPDATE_PHOTO:
            return {
                ...state,
                _update_photo: action.payload,
            };
        case LOGOUT:
            return initialState;

        case IMPORT_EMPLOYEE:
            return {
                ...state,
                _import_employee: action.payload,
            };
        case GET_REPORT_EMPLOYEE:
            return {
                ...state,
                _get_report_employee: action.payload
            }

        default:
            return state;
    }
};

export const getAllEmployees = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${EMPLOYEE_PATH}`, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_EMPLOYEES,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: GET_ALL_EMPLOYEES,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_EMPLOYEES,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const searchEmployees = async (token, paramsUrl) => {
    return await axios.get(`${URLAPI}${EMPLOYEE_PATH}${paramsUrl}`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
}

export const createEmployee = (token, data) => async (dispatch) => {
    try {
        let form = new FormData();

        for (var key in data) {
            form.append(key, data[key]);
        }
        const response = await axios.post(
            `${URLAPI}${EMPLOYEE_CREATE_PATH}`,
            form,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        dispatch({
            type: CREATE_EMPLOYEE,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: CREATE_EMPLOYEE,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_EMPLOYEE,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const updateEmployee = (token, id, data) => async (dispatch) => {
    try {
        let form = new FormData();

        for (var key in data) {
            form.append(key, data[key]);
        }
        const response = await axios.patch(
            `${URLAPI}${EMPLOYEE_UPDATE_PATH}${id}/`,
            form,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        dispatch({
            type: UPDATE_EMPLOYEE,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: UPDATE_EMPLOYEE,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_EMPLOYEE,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const get_report_employee = (data, token) => async (dispatch) => {
    try {
        const res = await axios.post(URLAPI + EMPLOYEE_REPORT_PATH, data, {
            headers: {
                Authorization: `Token ${token}`,
            },
        })

        console.log(res)

        dispatch({
            type: GET_REPORT_EMPLOYEE,
            payload: {
                data: res.data,
                status: res.status,
                message: ""
            }
        })
    } catch (e) {
        dispatch({
            type: GET_REPORT_EMPLOYEE,
            payload: {
                data: {},
                status: 500,
                message: "Ocurrio un error al generar el reporte"
            }
        })
    }
}

export const check_export_employee_status = async (token, task_id) => {
    return await axios.get(`${URLAPI}${EMPLOYEE_REPORT_CHECK_PATH}${task_id}/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    })
}


export const createLink = (token, data) => async (dispatch) => {
    try {

        const response = await axios.post(
            `${URLAPI}${EMPLOYEE_CREATE_LINK_PATH}`,
            data,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        dispatch({
            type: CREATE_LINK,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: CREATE_LINK,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_LINK,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
}


export const updatePhoto = (id, code, form) => async (dispatch) => {
    try {
        const response = await axios.patch(
            `${URLAPI}${EMPLOYEE_UPDATEPHOTO_PATH}${id}/${code}/`,
            form);
        dispatch({
            type: UPDATE_PHOTO,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: UPDATE_PHOTO,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });

        } else {
            dispatch({
                type: UPDATE_PHOTO,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
}

export const importEmployee = (token, data) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${URLAPI}${EMPLOYEE_IMPORT_PATH}`,
            data,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        dispatch({
            type: IMPORT_EMPLOYEE,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: IMPORT_EMPLOYEE,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: IMPORT_EMPLOYEE,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
}


export const get_status_task = async (task_id, token) => {
    const res = await axios.get(`${URLAPI}${TASK_PATH}${task_id}/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });

    return res;
};
export const capture_photo = (data) => async (dispatch) => {
    dispatch({
        type: CAPTURE_PHOTO,
        payload: data,
    });
}


export const clear_employee = (type) => async (dispatch) => {
    dispatch({
        type: type,
        payload: {
            data: {},
            status: 0,
            message: {},
        },
    });
};


export const validate_link = async (id, code) => {
    return await axios.get(`${URLAPI}${EMPLOYEE_VALIDATE_LINK_PATH}${id}/${code}/`)

}
