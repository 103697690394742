import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {createLink, CREATE_LINK, clear_employee} from '../../../redux/employees';
import Modal from "../../../components/modal";
import {TYPE_VISITANT} from '../../../config/index'
import {ToastContainer, toast} from 'react-toastify';
import {clear_visitant, delete_visitant, get_all_visitants, DELETE_VISITANT} from "../../../redux/visitant";


const Visitant = (props) => {
    let history = useHistory();
    let dispatch = useDispatch();
    const visitant = useSelector(
        (state) => state.visitant_module._get_all_visitants
    );
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [data, setData] = useState([]);
    useEffect(() => {
        if (visitant.data) {
            setData(visitant.data);
        }
    }, [visitant]);

    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);
    const [modal, setModal] = useState(false);
    const [id, setId] = useState(null);

    const _create_link = useSelector(
        (state) => state.employees_module._create_link
    );

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);

    useEffect(() => {
        if (_create_link.status === 200) {
            toast.success("Se envió el correo de confirmación al visitante");
            dispatch(clear_employee(CREATE_LINK));
        } else if (_create_link.status !== 0) {
            toast.error(_create_link.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clear_employee(CREATE_LINK));
        }
    }, [_create_link, dispatch]);

    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_visitant').length > 0) {
                act.push({
                    name: "edit",
                    label: "Editar",
                    icon: "bx bx-edit",
                    color: "primary",
                    onClick: (item) => {
                        history.push(`/invitados/editar/${item.id}`);
                    },
                })

                act.push({
                    name: "send-email",
                    label: "Enviar correo cargar foto",
                    icon: "bx bx-send",
                    color: "warning",
                    onClick: (item) => {
                        sendEmail(TYPE_VISITANT, item.id)
                    }
                });

            }
            if (permisos.filter(x => x.codigo === 'delete_visitant').length > 0) {
                act.push({
                    name: "delete",
                    label: "Eliminar",
                    icon: "bx bxs-trash",
                    color: "danger",
                    onClick: (item) => {
                        setModal(true);
                        setId(item.id)
                    },
                })

            }
        }
        setActions(act);
        // eslint-disable-next-line
    }, [permisos, history]);

    const sendEmail = (type = TYPE_VISITANT, id) => {
        dispatch(createLink(token, {
            tipo: type,
            id_visitante: id
        }))
    }

    const _delete_visitant = useSelector(
        (state) => state.visitant_module._delete_visitant
    );

    useEffect(() => {
        if (_delete_visitant.status === 200) {
            toast.success(_delete_visitant.data);
            dispatch(clear_visitant(DELETE_VISITANT));
            history.push("/invitados");
            dispatch(get_all_visitants(token));
            setModal(false);
            setId(null);
        } else if (_delete_visitant.status !== 0) {
            dispatch(clear_visitant(DELETE_VISITANT));
            toast.error(_delete_visitant.message);
        }
    }, [_delete_visitant, history, token, dispatch]);

    const handleDelete = () => {
        dispatch(delete_visitant(token, id));
    };


    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            <ToastContainer/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Invitados"
                            items={[{label: "Invitados", link: "/invitados"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                {
                                    permisos.filter(x => x.codigo === 'add_visitant').length > 0 ?
                                        <Link
                                            to="/invitados/crear"
                                            className="btn btn-success"
                                        >
                                            Crear
                                        </Link> : null
                                }

                                <div className="table-responsive">
                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: "rut",
                                                label: "Rut",
                                                type: "text",
                                            },
                                            {
                                                name: "nombres",
                                                label: "Nombres",
                                                type: "text",
                                            },
                                            {
                                                name: "apellidos",
                                                label: "Apellidos",
                                                type: "text",
                                            },
                                            {
                                                name: "foto",
                                                label: "Imagen",
                                                type: "avatar",
                                            },
                                            {
                                                name: "activo",
                                                label: "Activo",
                                                type: "boolean",
                                            },
                                        ]}
                                        actions={actions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main" show={modal} style={{width: "40%"}}>
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="text-center">
                            <h5>¿Está seguro de eliminar o inactivar el registro?</h5>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={() => {handleDelete()}}>
                            SI
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={() => {setModal(false)}}>
                            NO
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Visitant;
