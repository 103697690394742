import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {
    createSupplier,
    CREATE_SUPPLIER,
    clear_suppliers,
    getAllSuppliers
} from "../../../redux/supplier";

const CreateSupplier = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const _create_supplier = useSelector(
        (state) => state.supplier_module._create_supplier
    );
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [form, setForm] = useState({
        rut: "",
        nombre: "",
        imagen: "",
    });

    useEffect(() => {
        if (_create_supplier.status === 201) {
            dispatch(clear_suppliers(CREATE_SUPPLIER));
            history.push("/proveedores");
            dispatch(getAllSuppliers(token));
        } else if (_create_supplier.status !== 0) {
            dispatch(clear_suppliers(CREATE_SUPPLIER));
            toast.error(_create_supplier.message);
        }
    }, [_create_supplier, dispatch, history, token]);

    useEffect(() => {
    }, []);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleFileChange = (e) => {
        setForm({
            ...form,
            imagen: e.target.files[0],
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate fields
        if (form.nombre === "") {
            toast.error("Debe ingresar el rut");
            return;
        }
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre");
            return;
        }
        if (form.imagen == null) {
            toast.error("Debe seleccionar una imagen");
            return;
        }

        dispatch(createSupplier(token, form));
    };

    return (
        <div className="app-content content">
            <ToastContainer/>
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Proveedores"
                            items={[
                                {label: "Proveedores", link: "/proveedores"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Crear Proveedor</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                name="rut"
                                                label="RUT"
                                                value={form.rut}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div
                                            className="
                        col-md-6"
                                        >
                                            <Input
                                                name="nombre"
                                                label="Nombre"
                                                value={form.nombre}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                name="imagen"
                                                label="Imagen"
                                                type="file"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/proveedores" className="btn btn-danger m-1">
                                        Cancelar
                                    </Link>
                                    <Button
                                        type="submit"
                                        text="Crear"
                                        theme="success"
                                        classes="m-1"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateSupplier;
