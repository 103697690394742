import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import Select from "react-select";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectInput from "../../../components/select";

import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    clear_user_module,
    UPDATE_USER,
    update_user,
    get_all_users
} from "../../../redux/users";


const UpdateUser = () => {
    const {id} = useParams();


    const dispatch = useDispatch();
    const history = useHistory();

    const _get_all_cost_centers = useSelector(
        (state) => state.cost_center_module._get_all_cost_centers
    );

    const _get_all_groups = useSelector(
        (state) => state.users_module._get_all_groups
    );

    const _get_all_companies = useSelector(
        (state) => state.company_module._get_all_companies
    );

    const _get_all_users = useSelector((state) => state.users_module._get_all_users);

    const _update_user = useSelector((state) => state.users_module._update_user);


    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [cost_center_select, setCostCenterSelect] = useState([]);
    const [form, setForm] = useState({
        usuario: "",
        nombres: "",
        apellidos: "",
        correo: "",
        id_empresa: 0,
        id_grupo_permiso: 0,
        centros_costos: [],
        url_confirmacion: window.location.origin + "/activar-cuenta",
        empresa: "",
        grupo_permiso: "",
    });
    const [companies, setCompanies] = useState([]);
    const [cost_center, setCostCenter] = useState([]);
    const [groups, setGroups] = useState([]);

    const [optionsCostCenter, setOptionsCostCenter] = useState([]);

    useEffect(() => {
        if (_get_all_users.data) {
            const user = _get_all_users.data.filter(
                (user) => user.id === parseInt(id)
            );
            if (user.length > 0) {

                let centros_costos = user[0].centros_costos.map((cost_center) => {
                    return {
                        value: cost_center.codigo,
                        label: cost_center.nombre,
                    };
                })
                setCostCenterSelect(centros_costos);
                setForm({
                    usuario: user[0].usuario,
                    nombres: user[0].nombres,
                    apellidos: user[0].apellidos,
                    correo: user[0].correo,
                    empresa: user[0].empresa,
                    grupo_permiso: user[0].grupo_permiso.length > 0 ? user[0].grupo_permiso[0].nombre : "",
                    centros_costos: centros_costos,
                    url_confirmacion: window.location.origin + "/activar-cuenta",
                });
            }


        }
    }, [_get_all_users, id]);

    useEffect(() => {
        if (_update_user.status === 200) {
            dispatch(clear_user_module(UPDATE_USER));
            history.push("/usuarios");
            dispatch(get_all_users(token));
        } else if (_update_user.status !== 0) {
            dispatch(clear_user_module(UPDATE_USER));
            toast.error(_update_user.message);

        }
    }, [_update_user, dispatch, history, token]);


    useEffect(() => {
        if (_get_all_cost_centers.data) {
            setCostCenter(_get_all_cost_centers.data.datos)

            let options = _get_all_cost_centers.data.datos.map((item) => {
                return {
                    value: item.codigo,
                    label: item.nombre,
                };
            });

            options.unshift({
                value: "ALL",
                label: "TODOS",
            })
            setOptionsCostCenter(options);
        }

    }, [_get_all_cost_centers]);

    useEffect(() => {
        if (_get_all_companies.data) {

            setCompanies(_get_all_companies.data.datos);
        }
    }, [_get_all_companies]);


    useEffect(() => {
        if (_get_all_groups.data) {
            setGroups(_get_all_groups.data.datos);
        }
    }, [_get_all_groups]);

    const handleChange = (e) => {
        if (e.target.name === "id_grupo_permiso" || e.target.name === "id_empresa") {
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }

    };

    const handleChangeSelect = (e) => {
        let all = e.filter(x => x.value === "ALL")
        if (all.length > 0) {
            setCostCenterSelect(all)
        } else {
            setCostCenterSelect(e);

        }


    };

    const handleSubmit = (e) => {


        e.preventDefault();

        let all = cost_center_select.filter(x => x.value === "ALL")

        if (all.length > 0) {
            form.centros_costos = cost_center;

        } else {
            let array = cost_center_select.map((item) => {
                return {
                    codigo: item.value,
                    nombre: item.label,
                };
            });
            form.centros_costos = array;

        }
        //validate fields
        if (form.usuario === "") {
            toast.error("El campo usuario es obligatorio");
            return;
        }
        if (form.nombres === "") {
            toast.error("El campo nombres es obligatorio");
            return;
        }
        if (form.apellidos === "") {
            toast.error("El campo apellidos es obligatorio");
            return;
        }
        if (form.id_empresa === 0) {
            toast.error("El campo empresa es obligatorio");
            return;
        }
        if (form.id_grupo_permiso === 0) {
            toast.error("El campo grupo permiso es obligatorio");
            return;
        }
        if (form.centros_costos.length === 0) {
            toast.error("El campo centros costos es obligatorio");
            return;
        }
        if (form.correo === "") {
            toast.error("El campo correo es obligatorio");
            return;
        }
        dispatch(update_user(form, id, token));
    };


    return (
        <div className="app-content content">
            <ToastContainer/>
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Usuarios"
                            items={[
                                {label: "Usuarios", link: "/usuarios"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Actualizar Usuario</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                label="usuario"
                                                name="usuario"
                                                value={form.usuario}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                label="nombres"
                                                name="nombres"
                                                value={form.nombres}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                label="apellidos"
                                                name="apellidos"
                                                value={form.apellidos}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Empresa</label>
                                                <SelectInput
                                                    label="empresa"
                                                    name="id_empresa"
                                                    select={form.empresa}
                                                    onChange={handleChange}
                                                    options={companies ? companies.map((company) => ({
                                                        name: company.nombre,
                                                        id: company.id,
                                                    })) : []}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Grupo de Permisos</label>
                                                <SelectInput
                                                    label="Rol"
                                                    name="id_grupo_permiso"
                                                    select={form.grupo_permiso}
                                                    onChange={handleChange}
                                                    options={groups ? groups.map((group) => ({
                                                        name: group.nombre,
                                                        id: group.id,
                                                    })) : []}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Centro de costo</label>
                                                <Select
                                                    value={cost_center_select}
                                                    options={optionsCostCenter}
                                                    isMulti={true}
                                                    onChange={handleChangeSelect}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                label="Correo"
                                                name="correo"
                                                value={form.correo}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/usuarios" className="btn btn-danger m-1">
                                        Cancelar
                                    </Link>
                                    <Button type="submit" text="Guardar" theme="success"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateUser;
