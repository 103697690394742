import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from "../../../components/select";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    update_cost_centers,
    clear_cost_center,
    UPDATE_COST_CENTERS_SUCCESS,
    get_all_cost_centers,
} from "../../../redux/cost_center";

/** @module  Pages/CostCenter/UpdateCostCenters */

/**
 * @class UpdateCostCenters - Componente que renderiza la vista de actualizar centro de costo
 * @extends React.Component
 * @requires react-router-dom
 * @requires react-redux
 * @requires redux
 * @requires pages/admin/cost_center/update.js
 * @requires components/breadcrumbs
 * @requires components/button
 * @requires components/input
 * @requires components/alert
 * @requires components/select
 * @requires components/link
 * @requires redux/cost_center
 */

const UpdateCostCenters = () => {


    /**
     Un enlace que le permite usar el objeto de historial fuera de un componente.
     */
    const history = useHistory();


    /**
     *  Obtener la identificación de la url.
     */
    const {id} = useParams();


    /**
     *  Un enlace que le permite usar la función de despacho fuera de un componente.
     */
    const dispatch = useDispatch();


    /**
     *  Obtener los datos de la tienda redux.
     */
    const _cost_center = useSelector(
        (state) => state.cost_center_module._get_all_cost_centers
    );


    /**
     *  Obtener el token de la tienda redux.
     */
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    /**
     *  Obtener los datos de la tienda redux.
     */


    /* Obtener los datos de la tienda. */
    const _update_cost_centers = useSelector(
        (state) => state.cost_center_module._update_cost_centers
    );


    /* Obtener los datos de la tienda. */
    const _companies = useSelector(
        (state) => state.company_module._get_all_companies
    );

    const _zones = useSelector(
        (state) => state.zone_module._get_all_zones
    );


    /* Un estado que se utiliza para almacenar los datos del formulario. */
    const [form, setForm] = useState({
        codigo: "",
        nombre: "",
        id_empresa: 0,
        empresa: "",
        id_zona: 0,
        zona: "",
    });

    /* Un estado que se utiliza para almacenar los datos del formulario. */
    const [companies, setCompanies] = useState([]);
    const [zones, setZones] = useState([]);


    /**
     *
     *  Este es una funcion flecha que se llama después de cada renderizado.
     * cuando el endpoint de actualizar centro de costo responda
     */
    useEffect(() => {
        if (_update_cost_centers.status === 200) {
            dispatch(clear_cost_center(UPDATE_COST_CENTERS_SUCCESS));
            history.push("/centros-de-costos");
            dispatch(get_all_cost_centers(token));
        } else {
            if (_update_cost_centers.status !== 0) {
                toast.error(_update_cost_centers.message)
            }
        }
    }, [_update_cost_centers, dispatch, history, token]);


    /**
     * funcion que se llama después de cada renderizado. Se utiliza para obtener los datos de la
     * tienda.
     */
    useEffect(() => {
        if (_companies.data.datos) {
            setCompanies(_companies.data.datos);
        }
    }, [_companies]);

    useEffect(() => {
        if (_zones.data.datos) {
            let value_zones = _zones.data.datos.filter(x => x.activo === true)
            setZones(value_zones);
        }
    }, [_zones]);


    /**
     *  Este es una funcion que se llama después de cada renderizado.
     * Se utiliza para obtener los datos del centro de costo a actualizar
     */
    useEffect(() => {
        if (_cost_center.data) {
            // eslint-disable-next-line
            _cost_center.data.datos.map((item) => {
                if (parseInt(item.id) === parseInt(id)) {
                    setForm({
                        ...form,
                        codigo: item.codigo,
                        nombre: item.nombre,
                        id_empresa: item.id_empresa,
                        empresa: item.empresa,
                        id_zona: parseInt(item.id_zona),
                        zona: item.zona,
                    });
                }
            });
        }
        // eslint-disable-next-line
    }, [_cost_center, id]);

    // useEffect(() => {
    //     if (companies.length > 0) {
    //         // eslint-disable-next-line
    //         companies.map((item) => {
    //             // eslint-disable-next-line
    //             if (item.nombre === form.empresa) {
    //                 setForm({
    //                     ...form,
    //                     id_empresa: item.id,
    //                 });
    //                 console.log(item)
    //
    //             }
    //         })
    //     }
    //     // eslint-disable-next-line
    // }, [companies]);
    //
    // useEffect(() => {
    //     if (zones.length > 0) {
    //         // eslint-disable-next-line
    //         zones.map((item) => {
    //             // eslint-disable-next-line
    //             if (item.nombre === form.zona) {
    //                 console.log(item)
    //                 setForm({
    //                     ...form,
    //                     id_zona: item.id,
    //                 });
    //
    //             }
    //         })
    //     }
    //     // eslint-disable-next-line
    // }, [zones]);


    /**
     * Toma un evento como argumento, y si el nombre de destino del evento es igual a "id_empresa",
     * establece el id_empresa del formulario en el valor de destino del evento, analizado como un número
     * entero. De lo contrario, establece el formulario [e.target.name] en el valor objetivo del evento
     * @param e - El objeto de evento
     */
    const handleChange = (e) => {
        if (e.target.name === "id_empresa" || e.target.name === "id_zona") {
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    };

    /**
     * Es una función que toma como parámetro un evento, previene la acción por defecto del evento,
     * valida los campos del formulario, y si los campos son válidos, despacha una acción para actualizar
     * el centro de costo
     * @param e - evento
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        if (form.codigo === "") {
            toast.error("Debe ingresar el código");
            return;
        }
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre");
            return;
        }
        if (form.id_empresa === 0) {
            toast.error("Debe seleccionar la empresa");
            return;
        }

        if (form.id_zona === 0) {
            toast.error("Debe seleccionar la zona");
            return;
        }

        dispatch(update_cost_centers(token, id, form));

    };
    return (
        <div className="app-content content">
            <ToastContainer/>
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Centros de Costo"
                            items={[
                                {label: "Centros de costos", link: "/centros-de-costos"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Actualizar Centro de Costo</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Input
                                                label="Codigo"
                                                name="codigo"
                                                onChange={handleChange}
                                                value={form.codigo}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Nombre"
                                                name="nombre"
                                                onChange={handleChange}
                                                value={form.nombre}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Empresa</label>

                                                <Select
                                                    label="Empresa"
                                                    name="id_empresa"
                                                    options={companies.map((company) => ({
                                                        name: company.nombre,
                                                        id: company.id,
                                                    }))}
                                                    onChange={handleChange}
                                                    value={form.id_empresa}
                                                    select={form.empresa}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Zona</label>

                                                <Select
                                                    label="Zona"
                                                    name="id_zona"
                                                    options={zones.map((item) => ({
                                                        name: item.nombre,
                                                        id: item.id,
                                                    }))}
                                                    onChange={handleChange}
                                                    value={form.id_zona}
                                                    select={form.zona}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/centros-de-costos" className="btn btn-danger m-1">
                                        Cancelar
                                    </Link>
                                    <Button
                                        type="submit"
                                        text={
                                            <>
                                                <i className="bx bx-save"/> Guardar
                                            </>
                                        }
                                        theme="success"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateCostCenters;
