import React, { useState, useEffect } from 'react';
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import Checkbox from "../../../components/check-box";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createJob, clear_jobs, CREATE_JOB,getAllJobs } from '../../../redux/jobs';


const CreateJob = () => {
    const dispatch = useDispatch();
    const history = useHistory();

      const { token } = useSelector((state) => state.users_module.login.data)||null;


    const _create_job = useSelector((state) => state.jobs_module._create_job);
    /* A state variable that is used to store the form data. */
    const [form, setForm] = useState({
        nombre: "",
        visita_no_programada: false,
        visit_needs_approval: false,
        enviar_notificacion: false

    });

    useEffect(() => {
        console.log(_create_job.status )
        if (_create_job.status === 200) {
            toast.success("El cargo ha sido creado correctamente");
            dispatch(clear_jobs(CREATE_JOB));
            history.push("/cargos");
            dispatch(getAllJobs(token));

        } else {
            if (_create_job.status !== 0) {
                dispatch(clear_jobs(CREATE_JOB));
                toast.error(_create_job.message);
            }
        }
    }, [_create_job, dispatch, history, token]);

    const handleChange = (e) => {
        if (e.target.name === "visita_no_programada" || e.target.name==='visit_needs_approval'|| e.target.name==='enviar_notificacion') {
            setForm({
                ...form,
                [e.target.name]: e.target.checked
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value
            });
        }

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate fields
        if (form.nombre === "") {
            toast.error("El nombre es requerido");
            return;
        }
        dispatch(createJob(token, form));
    };
    return (
        <div className="app-content content">
            <ToastContainer/>
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Cargos"
                            items={[
                                { label: "Cargos", link: "/cargos" },
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Crear Cargo</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Input
                                                label="nombre"
                                                name="nombre"
                                                onChange={handleChange}
                                                value={form.nombre}
                                            />
                                        </div>
                                        <div className="col-md-4 mt-2">
                                            <Checkbox
                                                id="visita_no_programada"
                                                label="Visita no programada"
                                                name="visita_no_programada"
                                                onChange={handleChange}
                                                state={form.visita_no_programada}
                                            />

                                            {form.visita_no_programada?
                                                <Checkbox
                                                    id="visit_needs_approval"
                                                    label="Visita sin aprobación"
                                                    name="visit_needs_approval"
                                                    onChange={handleChange}
                                                    state={form.visit_needs_approval}
                                                />
                                            :
                                            null}
                                            <Checkbox
                                                id="enviar_notificacion"
                                                label="Enviar notificación"
                                                name="enviar_notificacion"
                                                onChange={handleChange}
                                                state={form.enviar_notificacion}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/cargos" className="btn btn-danger m-1">
                                        Cancelar
                                    </Link>
                                    <Button
                                        text="Crear"
                                        theme="success"
                                        type="submit"
                                        classes="m-1"
                                        onClick={handleSubmit}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default CreateJob;
