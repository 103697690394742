import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {createLink, CREATE_LINK, clear_employee, searchEmployees, get_report_employee} from '../../../redux/employees';
import {TYPE_EMPLOYEE} from '../../../config/index'
import {ToastContainer, toast} from 'react-toastify';
import Button from "../../../components/button";

const Employees = (props) => {
    let dispatch = useDispatch();
    let history = useHistory();
    const employees = useSelector(
        (state) => state.employees_module._get_all_employees
    );
    const _create_link = useSelector(
        (state) => state.employees_module._create_link
    );
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [data, setData] = useState([]);
    useEffect(() => {
        if (employees.data.datos) {
            // eslint-disable-next-line
            let empleados = employees.data.datos.filter((item) => {
                if (item.activo) {
                    return item;
                }
            })
            setData(empleados);
        }
    }, [employees]);

    useEffect(() => {
        if (_create_link.status === 200) {
            toast.success("Se envió el correo de confirmación al empleado");
            dispatch(clear_employee(CREATE_LINK));
        } else if (_create_link.status !== 0) {
            toast.error(_create_link.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clear_employee(CREATE_LINK));
        }
    }, [_create_link, dispatch]);

    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);


    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_employees').length > 0) {
                act.push({
                    name: "edit",
                    label: "Editar",
                    icon: "bx bx-edit",
                    color: "primary",
                    onClick: (item) => {
                        history.push(`/empleados/editar/${item.id}`);
                    },
                })
                act.push({
                    name: "send-email",
                    label: "Enviar correo cargar foto",
                    icon: "bx bx-send",
                    color: "warning",
                    onClick: (item) => {
                        sendEmail(TYPE_EMPLOYEE, item.id)
                    }
                });
            }
        }
        setActions(act);
        // eslint-disable-next-line
    }, [permisos, history]);


    const sendEmail = (type = TYPE_EMPLOYEE, id) => {
        dispatch(createLink(token, {
            tipo: type,
            id_empleado: id
        }))
    }

    const [active, setActive] = useState('1');

    const handleChange = async (e) => {
        console.log(e.target.value)
        const params = '?buscar=' + e.target.value.toString()
        const response = await searchEmployees(token, params)
        setData(response.data.datos)
        setActive(e.target.value.toString())
    };
    const _get_report_employee = useSelector((store) => store.employees_module._get_report_employee);

    const [report, setReport] = useState(false);

    useEffect(() => {
        console.log(_get_report_employee.status)
        setReport(_get_report_employee.status)
    }, [_get_report_employee])

    const handleGenerateReport = () => {
        console.log('generar reporte')
        dispatch(get_report_employee({'activo': active}, token))
    }

    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            <ToastContainer/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Empleados"
                            items={[{label: "Empleados", link: "/empleados"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-8 col-12 mb-1">
                                        {
                                            permisos.filter(x => x.codigo === 'add_employees').length > 0 ?
                                                <><Link to="/empleados/crear" className="btn btn-success">
                                                    Crear
                                                </Link>
                                                <Link to="/empleados/importar" className="ml-2 btn btn-outline-primary">
                                                    importar
                                                </Link></> : null
                                        }
                                        {!report ? <Button text={<h3><i className="text-white bx bx-export"/></h3>
                                        } theme="success" type="button"
                                                           classes="btn-lg round ml-2"
                                                           onClick={() => handleGenerateReport()}
                                        /> : <div className="spinner-border text-success ml-2"
                                                  role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>}
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group">
                                            <label>Filtrar</label>
                                            <select onChange={handleChange} className="form-control">
                                                <option value="1" selected>Activos</option>
                                                <option value="0">Inactivos</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-responsive">
                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: "foto",
                                                label: "Imagen",
                                                type: "avatar",
                                            },
                                            {
                                                name: "rut",
                                                label: "Rut",
                                                type: "text",
                                            },
                                            {
                                                name: "nombres",
                                                label: "Nombre",
                                                type: "text",
                                            },
                                            {
                                                name: "apellidos",
                                                label: "Apellidos",
                                                type: "text",
                                            },
                                            {
                                                name: "cargo",
                                                label: "Cargo",
                                                type: "text",
                                            },
                                            {
                                                name: "empresa",
                                                label: "Empresa",
                                                type: "text",
                                            },
                                            {
                                                name: "centro_costo",
                                                label: "Centro costo",
                                                type: "text",
                                            },
                                        ]}
                                        actions={actions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Employees;
