import React from "react";

/**  @module Components/Alert */
/**
 * Devuelve un div con una clase de alerta, un tipo y un rol de alerta.
 *
 * Dentro del div, hay un botón con una clase de cierre, un atributo de alerta de desestimación de
 * datos y una etiqueta aria de cierre.
 *
 * Dentro del botón, hay un lapso con un atributo de verdad oculto en aria.
 *
 * Fuera del botón, hay un div con una clase de d-flex align-items-center.
 *
 * Dentro del div, hay una i con una clase de icono y un lapso con un mensaje.
 *
 * La función tiene tres accesorios: mensaje, icono y tipo.
 *
 * La propiedad de mensaje es una cadena, la propiedad de icono es una cadena y la propiedad de tipo es
 * una cadena.
 *
 * El valor predeterminado de la propiedad de tipo es bg-rgba-success.
 *
 * La función también acepta un accesorio llamado dism.
 * @param {string} message - El mensaje que se mostrará en el alerta.
 * @param {string} icon - El icono que se mostrará en el alerta.
 * @param {string} type - El tipo de alerta que se mostrará en el alerta.
 * @param {boolean} dismisable - Si es true, el alerta se deshabilita.
 * @returns Un componente de reacción
 * @example
 * <Alert
 *      message="Mensaje del alerta" 
 *      icon="bx-check-circle"
 *      type="bg-rgba-success"
 *      dismisable={true}
 *    />
 */
const Alert = ({
  message,
  icon,
  type = "bg-rgba-success",
  dismisable = true,
}) => {
  return (
    <div className={`alert ${type} alert-dismissible mb-2`} role="alert">
      {dismisable ? (
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      ) : null}

      <div className="d-flex align-items-center">
        <i className={icon}></i>
        <span>{message}</span>
      </div>
    </div>
  );
};

export default Alert;
